<template>
    <div id="subscribePage">
      <!-- 头部 图片区域开始 -->
      <div class="header">
        <van-image
          class="img"
          fit="cover"
          :src="require('@/assets/images/icon_subscribe_01.png')"
        />
      </div>
      <!-- 头部 图片区域结束 -->
      <!-- 内容区域开始 -->
      <div class="content" >
        <div class="title">{{ $utils.formatDate(Date.now() , 'yyyy-MM-dd') }}から{{ detail.gradesName }}になりました！</div>
        <div class="center" v-if="detail.gradesInterests">{{ detail.gradesInterests.join(',') }}</div>
        <a class="aLink" @click="$router.push({name:'MemberIndex'})">他の会員ランクを確認</a>
      </div>
      <!-- 内容区域结束 -->
      <!-- 底部开始 -->
      <div class="bottom">
        <van-button @click="toHome" type="default" :hairline="false">ホームへ</van-button>
      </div>
    </div>
</template>

<script>

export default {
  name: 'subscribePage',
  data () {
    return {
      detail: {}
    };
  },
  mounted () {

    /* 获取领取新人礼包成功的内容 */
    this.getInfo();
  },
  methods: {
    getInfo () {
      this.$http.get('homePage/activity/view').then((res) => {
        this.detail = res.data || {};
      });
    },
    toHome () {
      const { $router } = this;
      $router.push({
        name: 'Home'
      });
    }
  }
};
</script>

<style scoped lang="less">
  #subscribePage {
    position: relative;
    height: 100%;

    .header {
      padding-top: 100px;

      .img {
        width: 558px;
        height: 470px;
        margin: 0 auto;
      }
    }

    .content {
      padding: 0 50px;
      margin-top: 60px;
      font-size: 24px;
      text-align: center;

      .title {
        margin-bottom: 24px;
        font-family: Hiragino Sans GB;
        font-size: 36px;
        color: #fff;
        opacity: 1;
      }

      .center {
        color: #fff;
        opacity: 0.7;
      }

      .aLink {
        margin-top: 48px;
        font-size: 24px;
        color: #36abf5;
        text-decoration: underline;
        opacity: 0.7;
      }
    }

    .bottom {
      position: absolute;
      right: 0;
      bottom: 226px;
      left: 0;
      padding: 0 28px;
      margin: auto;

      /deep/ button {
        width: 100%;
        height: 96px;
        font-family: Hiragino Sans;
        font-size: 28px;
        color: #4d2323;
        background: linear-gradient(180deg, #ffd64a 0%, #d2a408 100%);
        border: none;
        border-radius: 16px;
        opacity: 1;
      }
    }
  }
</style>